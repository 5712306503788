<template>
  <div>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
    <div class="card p-grid">
      <div class="cont" :style="{width:current_type!==2?'100%':'60%'}">
        <div style="display: flex;justify-content: center">
          <ul class="uul">
            <li v-for="(v,index) in items" :key="index" :class="current_type === index ? 'typeActive' : ''" @click="checkoutType(index)">
              {{ v.label }}
            </li>
          </ul>
        </div>
        <div style="margin: 0 auto;width: 100%;padding-top: 22px;">
          <div v-show="current_type===0">
            <div class="p-fluid" style="display: flex;flex-wrap: wrap">
              <div class="p-field p-grid p-col-12">
                <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>项目名称:</label>
                <div class="p-col">
                  <InputText v-model="form.name" :class="{'p-invalid':(v$.form.name.$invalid && submitted)}"></InputText>
                  <span v-if="v$.form.name.required.$invalid && submitted" style="font-size: 12px;color: red">请输入项目名称！</span>
                </div>
              </div>
              <div class="leftBlock p-col-6">
                <div class="p-field p-grid">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end">项目圈地:</label>
                  <div class="p-col" style="color: #24bd7f;">
                    <span style="padding-right:10px;cursor:pointer;" @click="addOrUpdate"><i class="pi pi-map-marker"></i>绘制项目地块轮廓</span>
                    <show-map style="margin-top: 10px;height: 300px" ref="showMap" :update-but="false" @update="addOrUpdate(0,mapInfo)"></show-map>
                    <span v-if="v$.form.positionLon.required.$invalid && submitted" style="font-size: 12px;color: red;margin-top: 4px;">请进行项目圈地！</span>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>项目坐标:</label>
                  <div class="p-col colee">
                    <InputText placeholder="绘制项目地块轮廓后自动计算经度" style="margin-right: 15px" v-model="form.positionLon" disabled="disabled" :class="{'p-invalid':(v$.form.positionLon.$invalid && submitted)}"></InputText>
                    <InputText placeholder="绘制项目地块轮廓后自动计算维度" v-model="form.positionLat" disabled="disabled"></InputText>
                  </div>
                </div>
                <div class="p-field p-grid ">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end">项目面积:</label>
                  <div class="p-col cols">
                    <InputText class="p-mr-3" placeholder="绘制项目地块轮廓后自动计算" v-model="form.area"></InputText>
                    <Dropdown v-model="form.areaUnit" :options="cities" optionLabel="name" option-value="name"/>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>项目地点:</label>
                  <div class="p-col cols">
                    <InputText class="inp" placeholder="国家" v-model="form.country" :class="{'p-invalid':(v$.form.country.$invalid && submitted)}" style="margin-right: 16px;"></InputText>
                    <InputText class="inp" placeholder="省/洲/自治区" v-model="form.province" :class="{'p-invalid':(v$.form.province.$invalid && submitted)}" style="margin-right: 16px;"></InputText>
                    <InputText class="inp" placeholder="市" v-model="form.city" :class="{'p-invalid':(v$.form.city.$invalid && submitted)}" style="margin-right: 16px;"></InputText>
                    <InputText class="inp" placeholder="区/县" v-model="form.district" :class="{'p-invalid':(v$.form.district.$invalid && submitted)}"></InputText>
                  </div>
                  <span style="font-size: 12px;color: #79828a;margin-left: 110px; width: calc(100% - 110px)">
                    提示：省/自治区、市、区/县的命名应使用简称，如内蒙古自治区简称内蒙古，阿拉善盟简称阿拉善<br>
                    <span v-if="(v$.form.country.required.$invalid || v$.form.province.required.$invalid ||v$.form.city.required.$invalid && submitted || v$.form.district.required.$invalid) && submitted"
                          style="font-size: 12px;color: red">请输入完整的地点！</span>
                  </span>
                </div>

                <div class="p-field p-grid">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>详细地址:</label>
                  <div class="p-col">
                    <InputText v-model="form.location" :class="{'p-invalid':(v$.form.location.$invalid && submitted)}"></InputText>
                    <span v-if="v$.form.location.required.$invalid && submitted" style="font-size: 12px;color: red;">请输入详细地址！</span>
                  </div>
                </div>
              </div>
              <div class="rightBlock p-col-6">

                <div class="p-field p-grid">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end;align-items: flex-start">当地天气:</label>
                  <div class="p-col">
                    <p style="color:#19a423;cursor: pointer" @click="getWeather">点击获取第三方天气数据</p>
                    <div style="display: flex;position:relative;flex-wrap: wrap;border:1px solid #ced4da;padding-top: 1rem;border-radius: 3px">
                      <div v-if="getWeatherIng" class="weatherBlackUI">
                        <div>加载中...</div>
                      </div>
                      <div class="weatherTable">
                        <label>年平均气温</label>
                        <InputNumber v-model="form.weatherData.t" style="display:inline-block;"></InputNumber>
                        <span>°C</span>
                      </div>
                      <div class="weatherTable">
                        <label>年平均最高气温</label>
                        <InputNumber v-model="form.weatherData.tmax" style="display:inline-block;"></InputNumber>
                        <span>°C</span>
                      </div>
                      <div class="weatherTable">
                        <label>年平均最低气温</label>
                        <InputNumber v-model="form.weatherData.tmin" style="display:inline-block;"></InputNumber>
                        <span>°C</span>
                      </div>
                      <div class="weatherTable">
                        <label>年度降雨量</label>
                        <InputNumber v-model="form.weatherData.pp" style="display:inline-block;"></InputNumber>
                        <span>mm</span>
                      </div>
                      <div class="weatherTable">
                        <label>年度下雨天数</label>
                        <InputNumber v-model="form.weatherData.ra" style="display:inline-block;"></InputNumber>
                        <span>天</span>
                      </div>
                      <div class="weatherTable">
                        <label>年度下雪天数</label>
                        <InputNumber v-model="form.weatherData.sn" style="display:inline-block;"></InputNumber>
                        <span>天</span>
                      </div>
                      <div class="weatherTable">
                        <label>年度雷阵雨天数</label>
                        <InputNumber v-model="form.weatherData.ts" style="display:inline-block;"></InputNumber>
                        <span>天</span>
                      </div>
                      <div class="weatherTable">
                        <label>年度起雾天数</label>
                        <InputNumber v-model="form.weatherData.fg" style="display:inline-block;"></InputNumber>
                        <span>天</span>
                      </div>
                      <div class="weatherTable">
                        <label>年度龙卷风天数</label>
                        <InputNumber v-model="form.weatherData.tn" style="display:inline-block;"></InputNumber>
                        <span>天</span>
                      </div>
                      <div class="weatherTable">
                        <label>年度冰雹天数</label>
                        <InputNumber v-model="form.weatherData.gr" style="display:inline-block;"></InputNumber>
                        <span>天</span>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="p-field p-grid ">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>整地条件:</label>
                  <div class="p-col colee" style="height: 21px;">
                    <p v-for="(item,index) in allList" :key="index">
                      <RadioButton :id="'m'+index" name="menu" :value="item.name" v-model="form.cond" :class="{'p-invalid':(v$.form.cond.$invalid && submitted)}"/>
                      <label :for="'m'+index" style="margin-left: 4px;">{{ item.name }}</label>
                    </p>
                  </div>
                  <span v-if="v$.form.cond.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 110px;width: calc(100% - 110px)">请输入整地条件！</span>
                </div>
                <div class="p-field p-grid" style="margin-bottom: 0;margin-top: 26px;">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>灌溉条件:</label>
                  <div class="p-col colee" style="height: 21px;">
                    <p v-for="(item,index) in allLists" :key="index" @click="selectOne()">
                      <RadioButton :id="'a'+index" name="menu" :value="item.name" v-model="form.irrigation" :class="{'p-invalid':(v$.form.irrigation.$invalid && submitted)}"/>
                      <label :for="'a'+index" style="margin-left: 4px;">{{ item.name }}</label>
                    </p>
                  </div>
                  <span v-if="v$.form.irrigation.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 110px;width: calc(100% - 110px)">请输入灌溉条件！</span>
                </div>
                <div class="p-field p-grid" style="margin-top: 6px;">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end"></label>
                  <div class="p-col cols">
                    <InputNumber class="p-mr-3" placeholder="请输入金额" v-model="form.irrigationPrice" :class="{'p-invalid':(v$.form.irrigationPrice.$invalid && submitted),
                  'p-disabled':form.irrigation==='自然降雨'}"></InputNumber>
                    <Dropdown v-model="form.irrigationPriceUnit" :options="citie" :class="{'p-invalid':(v$.form.irrigationPriceUnit.$invalid && submitted)}" optionLabel="name" option-value="name"/>
                  </div>
                  <span v-if="(v$.form.irrigationPrice.required.$invalid || v$.form.irrigationPrice.figure.$invalid || v$.form.irrigationPriceUnit.required.$invalid) && submitted"
                        style="font-size: 12px;color: red;margin-left: 110px;margin-top: 4px;width: calc(100% - 110px)">请输入正确或完整的金额！</span>
                </div>
                <div class="p-field p-grid">
                  <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>土壤属性:</label>
                  <div class="p-col colee" style="flex-wrap: wrap">
                    <p v-for="(item,index) in allLi" :key="index" @click="faklsd(item.id)">
                      <RadioButton :id="'n'+index" name="menu" :value="item.name" v-model="form.soil" :class="{'p-invalid':(v$.form.soil.$invalid && submitted)}"/>
                      <label :for="'n'+index" style="margin-left: 4px;">{{ item.name }}</label>
                    </p>

                  </div>

                  <span v-if="v$.form.soil.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 110px;width: calc(100% - 110px)">请输入土壤属性！</span>

                  <div v-if="form.soil==='盐碱地'" style="margin-left: 110px;width: calc(100% - 110px); border: 1px solid #cccccc;margin-top: 6px;padding-bottom: 8px;">
                    <div class="p-field p-grid" style="margin-bottom: 0">
                      <label class="p-col-fixed" style="width:100px;justify-content: flex-end;margin-top: 8px;">土壤属性</label>
                      <div class="p-col colee">
                        <Slider v-model="form.soilPh" :min="0" :max="20" style="width: 80px;margin-top: 23px"/>
                        <label class="p-col-fixed" style="margin: 13px 5px 0 5px;">pH值</label>
                        <InputNumber v-model="form.soilPh" style="width: 100px;margin-top: 8px;" :class="{'p-invalid':(v$.form.soilPh.$invalid && submitted)}"></InputNumber>
                      </div>
                    </div>
                    <span v-if="v$.form.soilPh.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 110px;margin-top: 4px;width: calc(100% - 110px)">请输入pH值！</span>
                    <div class="p-field p-grid" style="margin-top: 26px;">
                      <label class="p-col-fixed" style="width:100px;justify-content: flex-end">含盐量:</label>
                      <div class="p-col">
                        <Dropdown style="width: 110px;" v-model="form.soilSalt" :options="yan" optionLabel="name" option-value="name"/>
                      </div>

                      <!-- <span v-if="v$.form.soilSalt.required.$invalid && submitted" style="font-size: 12px;color: red;margin-top: 6px;width: calc(100% - 110px);margin-left: 110px">请输入含盐量！</span> -->
                    </div>
                    <div class="p-field p-grid" style="margin-bottom: 0;">
                      <label class="p-col-fixed" style="width:100px;justify-content: flex-end">电导率(EC)</label>
                      <div class="p-col colee">
                        <InputNumber style="width: 110px;margin-right: 18px;margin-bottom: 4px;" v-model="form.soilConductivity">
                        </InputNumber>
                        <Dropdown style="width: 110px;margin-bottom: 4px;" v-model="form.soilConductivityUnit" :options="dao" option-value="name" optionLabel="name"/>
                      </div>
                    </div>
                    <!-- <span v-if="(v$.form.soilConductivity.required.$invalid || v$.form.soilConductivityUnit.required.$invalid) && submitted"
                      style="font-size: 12px;color: red;margin-left: 110px;margin-top: 6px;width: calc(100% - 110px)">请输入正确的电导率或单位！</span> -->
                  </div>
                </div>

              </div>

              <div style="display: flex;width: 100%;justify-content: center">
                <Button v-if="roleId===3" @click="list(0)" class="p-button-text" style="width:auto;margin-right: 15px;border: 1px solid #cccccc;white-space: nowrap">
                  保存为草稿
                </Button>
                <Button style="width:auto;white-space: nowrap" @click="list()">下一步：种植菌草用途</Button>
              </div>

            </div>
          </div>
          <div v-show="current_type===1">
            <div class="p-fluid" style="width: 70%;margin: 0 auto">
              <div class="p-field p-grid">
                <label class="p-col-fixed" style="width:210px;justify-content: flex-end">用于生态治理:</label>
                <div class="p-col colee">
                  <span style="padding-right: 20px" v-for="(item,index) in yes" :key="index" @click="checkbox=[]">
                    <RadioButton :id="'b'+index" name="menu" :value="item.id" v-model="form.isEcoGovernance"/>
                    <label :for="'b'+index" style="margin-left: 4px;">{{ item.name }}</label>
                  </span>
                </div>
              </div>
              <div class="p-field p-grid" v-if="form.isEcoGovernance===0">
                <label class="p-col-fixed" style="width:210px;justify-content: flex-end;align-items: flex-start;">生态治理目标:</label>
                <div class="p-col p-d-flex p-flex-wrap">
                  <div style="width: 120px;padding-right: 20px;padding-bottom: 20px" v-for="(item,index) in process" :key="index">
                    <Checkbox :id="'c'+index" name="menu" :value="item.name" v-model="checkbox"/>
                    <label :for="'c'+index" style="margin-left: 4px;">{{ item.name }}</label>
                  </div>
                </div>
              </div>
              <div class="p-field p-grid">
                <label class="p-col-fixed" style="width:210px;justify-content: flex-end">菌草成熟后再利用:</label>
                <div class="p-col colee">
                  <span style="padding-right: 20px" v-for="(item,index) in yes" :key="index">
                    <RadioButton :id="'d'+index" name="menu" :value="item.id" v-model="form.isReuse" @click="checkboxs=[]"/>
                    <label :for="'d'+index" style="margin-left: 4px;">{{ item.name }}</label>
                  </span>
                </div>
              </div>
              <div class="p-field p-grid" v-if="form.isReuse===0">
                <label class="p-col-fixed" style="width:210px;justify-content: flex-end;align-items: flex-start;">菌草成熟收获后的加工目标:</label>
                <div class="p-col p-d-flex p-flex-wrap">
                  <div style="width: 120px;padding-right: 20px;padding-bottom: 20px" v-for="(item,index) in target" :key="index">
                    <Checkbox :id="'e'+index" name="menu" :value="item.name" v-model="checkboxs"/>
                    <label :for="'e'+index" style="margin-left: 4px;">{{ item.name }}</label>
                  </div>
                </div>
              </div>
              <div style="display: flex;width: 100%;justify-content: center">
                <Button v-if="roleId===3" @click="isDraftSubmit" class="p-button-text" style="width:auto;margin-right: 15px;border: 1px solid #cccccc;white-space: nowrap">
                  保存为草稿
                </Button>
                <Button v-if="roleId===3" style="width: auto;white-space: nowrap" @click="notDraftSubmit">
                  正式提交项目
                </Button>
                <Button v-if="roleId===1" @click="checkoutType(2)" style="width: auto;white-space: nowrap">
                  下一步：其他信息
                </Button>
              </div>
            </div>
          </div>
          <div v-if="roleId===1 && current_type===2">
            <div class="p-field p-grid">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>项目编号:</label>
              <div class="p-col">
                <InputText v-model="form.code" :class="{'p-invalid':(v$.form.code.$invalid && submitted)}"></InputText>
                <span v-if="v$.form.code.required.$invalid && submitted" style="font-size: 12px;color: red">请输入项目编号！</span>
              </div>
            </div>
            <div class="p-field p-grid">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>项目类型:</label>
              <div class="p-col">
                <Dropdown v-model="form.type" :options="typeList" option-value="code" option-label="name" :class="{'p-invalid':(v$.form.type.$invalid && submitted)}"></Dropdown>
                <span v-if="v$.form.type.required.$invalid && submitted" style="font-size: 12px;color: red">请输入项目类型！</span>
              </div>
            </div>
            <div class="p-field p-grid">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>项目目标:</label>
              <div class="p-col">
                <Textarea v-model="form.aim" :class="{'p-invalid':(v$.form.aim.$invalid && submitted)}"></Textarea>
                <span v-if="v$.form.aim.required.$invalid && submitted" style="font-size: 12px;color: red">请输入项目目标！</span>
              </div>
            </div>
            <div class="p-field p-grid" v-if="form.type && form.type.indexOf('科研')>-1">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end">实验效果:</label>
              <div class="p-col">
                <Textarea v-model="form.effect"></Textarea>
              </div>
            </div>
            <div class="p-field p-grid">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end">
                <span style="color:red;margin-right: 4px;">*</span>
                项目周期:</label>
              <div class="p-col">
                <Calendar selectionMode="range" :manualInput="false" v-model="form.zhouqi" :class="{'p-invalid':(v$.form.zhouqi.$invalid && submitted)}" dateFormat="yy/mm/dd"></Calendar>
                <span v-if="v$.form.zhouqi.required.$invalid && submitted" style="font-size: 12px;color: red;display: block">请输入项目周期！</span>
              </div>
            </div>
            <div class="p-field p-grid">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end">备注:</label>
              <div class="p-col">
                <Textarea v-model="form.comments"></Textarea>
              </div>
            </div>
            <div class="p-field p-grid">
              <Button style="margin-left: 110px" @click="addProject">导入到项目</Button>
            </div>
          </div>
        </div>
        <enclosure ref="addOrUpdateRole" v-if="addOrUpdateDisplay" @mapInfo="getMapInfo" v-on:close="closeDialog"></enclosure>
      </div>
      <Toast/>
    </div>
  </div>
</template>

<script>
import Slider from 'primevue/slider';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import enclosure from './enclosure';
import ShowMap from '@/views/modules/plan/showMap';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  name: 'plan',
  components: {ShowMap, Slider, RadioButton, Checkbox, enclosure},
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      form: {
        name: {required},
        country: {required},
        province: {required},
        city: {required},
        district: {required},
        area: {},
        cond: {required},
        irrigation: {required},
        irrigationPrice: {required, figure: this.$fromCheck.figure},
        soil: {required},
        soilPh: {required},
        // soilSalt: { required },
        // soilConductivity: { required },
        irrigationPriceUnit: {required},
        // soilConductivityUnit: { required },
        areaUnit: {},
        location: {required},
        positionLon: {required},
        code: {required},
        type: {required},
        aim: {required},
        effect: {},
        zhouqi: {required},
      },
    };
  },

  data() {
    return {
      form: {
        name: null, //项目名称
        country: null, //国家
        province: null, //省/洲/自治区
        city: null, //市
        district: null, //区/县
        location: null, //具体地址
        area: null, //项目面积
        areaUnit: null, //项目面积单位
        positionLon: null, //项目坐标，经度
        positionLat: null, //项目坐标，纬度
        cond: null, //整地条件
        irrigation: null, //灌溉条件
        irrigationPrice: null, //每亩单价
        irrigationPriceUnit: '元/亩', //每亩单价的单位
        soil: null, //土壤属性
        soilPh: null, //土壤ph值
        soilSalt: null, //含盐量
        soilConductivity: null, //电导率
        soilConductivityUnit: 'ms/cm', //电导率单位
        isEcoGovernance: null, //用于生态治理，是：0，否1
        ecoGovernanceAim: null, //生态治理目标
        isReuse: null, //菌草成熟后再利用，再利用0，非再利用1
        procAim: null, //菌草成熟收获后的加工目标
        expertId: null, //分配的专家id
        areaMap: null, //圈地经纬度表,
        isDraft: 0,
        code: null,
        type: null,
        aim: null, //目标
        effect: null, //效果
        zhouqi: null, //周期
        comments: null, //备注
        startDate: null,
        endDate: null,
        planId: null,
        yield: null,
        weatherData: {
          year: null,
          t: null,
          tmax: null,
          tmin: null,
          pp: null,
          v: null,
          ra: null,
          sn: null,
          ts: null,
          fg: null,
          tn: null,
          gr: null,
        },
      },
      current_type: 0,
      askey: false,
      checkbox: [],
      checkboxs: [],
      submitted: false,
      process: [
        {id: 0, name: '水土流失'},
        {id: 1, name: '荒漠化'},
        {id: 2, name: '防风固沙'},
        {id: 3, name: '石漠化'},
        {id: 4, name: '盐碱地'},
        {id: 5, name: '矿山修复'},
        {id: 6, name: '土壤修复'},
        {id: 7, name: '护坡固堤'},
      ],
      target: [
        {id: 0, name: '菌料'},
        {id: 1, name: '饲料'},
        {id: 2, name: '生物燃料'},
        {id: 3, name: '新型板材'},
        {id: 4, name: '纤维材料'},
      ],
      breadcrumb: {
        home: {label: '种植规划', to: '/plan'},
        items: [
          {label: '创建规划', to: '#'},
        ],
      },
      items: [
        {label: '规划地块信息'},
        {label: '种植菌草用途'},
        {label: '其他信息'},
      ],
      typeList: [
        {name: '科研/防风固沙', code: '科研/防风固沙'},
        {name: '科研/根系越冬', code: '科研/根系越冬'},
        {name: '科研/环境监测', code: '科研/环境监测'},
        {name: '生产/育种', code: '生产/育种'},
        {name: '生产/生产田间管理', code: '生产/生产田间管理'},
        {name: '生产/猪饲料-发酵床', code: '生产/猪饲料-发酵床'},
        {name: '生产/种菇', code: '生产/种菇'},
      ],
      bools: false,
      bool: true,
      selectedCity: {name: '亩'},
      selec: {name: '元/亩'},
      daindao: {name: 'ms/cm'},
      hanyan: {name: '<0.3%'},
      cities: [{name: '亩'}],
      citie: [{name: '元/亩'}],
      dao: [{name: 'ms/cm'}, {name: 'μs/cm'}],
      yan: [{name: '<0.3%'}, {name: '0.3~0.6%'}, {name: '>0.6%'}],
      allList: [
        {id: 0, name: '平地'},
        {id: 1, name: '小于25度坡地'},
        {id: 2, name: '梯田'},
      ],
      allLists: [
        {id: 0, name: '喷灌'},
        {id: 1, name: '滴灌'},
        {id: 2, name: '浸灌'},
        {id: 3, name: '自然降雨'},
      ],
      allLi: [
        {id: 0, name: '红壤'},
        {id: 1, name: '沙壤地'},
        {id: 2, name: '湿地(草甸/沼泽地)'},
        {id: 3, name: '黑土'},
        {id: 4, name: '盐碱地'},
      ],
      type: null,
      types: null,
      typ: null,
      value: null,
      yes: [
        {id: 0, name: '是'},
        {id: 1, name: '否'},
      ],
      govern: null,
      liyong: null,
      addOrUpdateDisplay: false,

      mapInfo: null,

      addOrCreate: 0, //0 创建项目 1导入项目
      planId: null,
      stzimb: false,
      roleId: null,
      getWeatherIng: false,
      yield: null,
    };
  },
  mounted() {
    const roleCode = JSON.parse(localStorage.getItem('userinfo')).user.roleCode;
    if (roleCode === 'admin') {
      this.roleId = 1;
    } else if (roleCode === 'user') {
      this.roleId = 2;
    } else if (roleCode === 'ncz') {
      this.roleId = 3;
    }
    // this.roleId = Number(
    //   JSON.parse(localStorage.getItem("userinfo")).user.roleId
    // );
    if (this.roleId !== 1) {
      this.items.splice(2, 1);
    }
    let id = this.$route.query.id;
    if (id) {
      this.planId = id;
      if (this.roleId === 3) {
        this.form.id = id;
      } else if (this.roleId === 1) {
        this.form.planId = id;
        this.$http(
            '/suggest/getByPlanId',
            'get',
            {
              id: id,
            },
            (res) => {
              if(res.data&&res.data.yield!=null){
                this.yield = res.data.yield;
              }
              
            },
        );
      }
      this.getPlan();
    }
  },
  methods: {
    selectOne() {
      if (this.form.irrigation === '自然降雨') {
        this.form.irrigationPrice = 0;
      } else {
        this.form.irrigationPrice = null;
      }
    },
    getPlan() {
      this.$http('plan/getById', 'get', {id: this.planId}, (res) => {
        this.form = res.data;

        if (!res.data.weatherData) {
          this.form.weatherData = {
            year: null,
            t: null,
            tmax: null,
            tmin: null,
            pp: null,
            v: null,
            ra: null,
            sn: null,
            ts: null,
            fg: null,
            tn: null,
            gr: null,
          };
        }
        if (this.form.soil === '盐碱地') {
          this.askey = true;
        } else {
          this.form.soilPh=7
          this.askey = false;
        }
        this.stzimb = this.form.isEcoGovernance === 0;
        this.mapInfo = {
          area: res.data.area,
          path: JSON.parse(res.data.areaMap),
          center: [res.data.positionLon, res.data.positionLat],
        };
        this.stzimb = this.form.isEcoGovernance == 0;
        if (this.form.ecoGovernanceAim) {
          if (this.form.ecoGovernanceAim.indexOf(',') != -1) {
            this.checkbox = this.form.ecoGovernanceAim.split(',');
          } else {
            this.checkbox.push(this.form.ecoGovernanceAim);
          }
        }
        if (this.form.procAim) {
          if (this.form.procAim.indexOf(',') != -1) {
            this.checkboxs = this.form.procAim.split(',');
          } else {
            this.checkboxs.push(this.form.procAim);
          }
        }
        if (this.roleId === 3) {
          //修改规划
          this.items.splice(2, 1);
          this.addOrCreate = 0;
          this.breadcrumb.items[0].label = '修改规划';
        }
        if (this.roleId === 1) {
          this.form.id = null;
        }
        // this.form.ecoGovernanceAim = this.checkbox.toString()
        // this.form.procAim = this.checkboxs.toString()
        this.$nextTick(() => {
          this.$refs.showMap.update(this.mapInfo);
        });
      });
    },
    faklsd(id) {
      if (id === 4) {
        this.askey = true;
        this.form.soilPh = null; //土壤ph值
        this.form.soilSalt = null; //含盐量
      } else {
        this.askey = false;
        this.form.soilPh = 7; //土壤ph值
      }
    },
    list(type) {
      if (type === 0) {
        this.isDraftSubmit();
      } else {
        this.current_type = 1;
      }
    },
    addOrUpdate(item, info) {
      this.addOrUpdateDisplay = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdateRole.init(item, info);
      });
    },
    getMapInfo(e) {
      if (!e) {
        e = this.mapInfo;
      } else {
        this.mapInfo = e;
      }

      if (e) {
        e = JSON.parse(JSON.stringify(e));
        this.form.positionLat = e.center.Q;
        this.form.positionLon = e.center.R;
        if (e.area) {
          this.form.area = Number(e.area.toFixed(2));
          this.form.areaUnit = '亩';
          this.form.areaMap = e.path;
        } else {
          this.form.area = 0;
          this.form.areaUnit = null;
          this.form.areaMap = null;
        }
        this.form.city = e.city.city;
        this.form.province = e.city.province;
        this.form.district = e.city.district;
        this.form.country = '中国';
        //this.form.weatherData = e.weather

        this.$nextTick(() => {
          this.$refs.showMap.update(e);
        });
      }
    },
    closeDialog() {
      this.addOrUpdateDisplay = false;
    },
    checkoutType(index) {
      this.submitted = false;
      this.current_type = index;

      if (index === 0) {
        this.$nextTick(() => {
          this.$refs.showMap.update(this.mapInfo);
        });
      }
      // this.getMapInfo();
    },
    isDraftSubmit() {
      if (this.form.soil != '盐碱地') {
        this.form.soilPh = null; //土壤ph值
      }
      this.form.code = null;
      this.form.type = null;
      this.form.effect = null;
      this.form.aim = null;
      this.form.zhouqi = null;

      this.form.isDraft = 0;
      this.submit();
    },
    notDraftSubmit() {
      this.submitted = true;
      this.form.code = 'a';
      this.form.type = 1;
      this.form.effect = 'a';
      this.form.aim = 'a';
      this.form.zhouqi = 'a';

      if (this.v$.$invalid) {
        this.current_type = 0;
        return;
      }
      if (this.form.soil != '盐碱地') {
        this.form.soilPh = null; //土壤ph值
      }
      this.form.code = null;
      this.form.type = null;
      this.form.effect = null;
      this.form.aim = null;
      this.form.zhouqi = null;

      this.form.isDraft = 1;
      this.submit();
    },
    submit() {
      if (typeof this.form.areaMap !== 'string') {
        this.form.areaMap = JSON.stringify(this.form.areaMap);
      }
      this.form.ecoGovernanceAim = this.checkbox.toString();
      this.form.procAim = this.checkboxs.toString();
      Object.values(this.form.weatherData).forEach((value) => {
        if (value) {
          this.form.weatherData.year = Number(new Date().getFullYear()) - 1;
        }
      });
      delete this.form.createTime;

      this.$http(
          '/plan/addOrUpdate',
          'post',
          this.form,
          (res) => {
            this.$toast.add({
              severity: 'success',
              summary: '创建成功',
              life: 3000,
            });
            this.$router.push('/plan');
          },
          (obj) => {
            console.log(obj);
            this.$toast.add({
              severity: 'success',
              summary: '项目名存在',
              life: 3000,
            });
          },
      );
    },
    addProject() {
      this.submitted = true;
      // if (this.form.soil != "盐碱地") {
      //   this.form.soilPh = "q"; //土壤ph值
      //   this.form.soilSalt = "q"; //含盐量
      //   this.form.soilConductivity = "q"; //电导率
      //   this.form.soilConductivityUnit = "q"; //电导率单位
      // }
      if (this.v$.$invalid) {
        return;
      }
      if (this.form.soil != '盐碱地') {
        this.form.soilPh = null; //土壤ph值
        // this.form.soilSalt = null; //含盐量
        // this.form.soilConductivity = null; //电导率
        // this.form.soilConductivityUnit = null; //电导率单位
      }
      if (this.form.zhouqi) {
        this.form.startDate = this.form.zhouqi[0];
        this.form.endDate = this.form.zhouqi[1];
      }
      this.form.ecoGovernanceAim = this.checkbox.toString();
      this.form.procAim = this.checkboxs.toString();
      this.form.planId = this.planId;
      this.form.yield = this.yield;

      //console.log(this.form)
      this.$http(
          'project/saveOrUpdate',
          'post',
          this.form,
          (res) => {
            console.log(res);
            if (res.success) {
              this.$toast.add({
                severity: 'success',
                summary: '导入成功',
                life: 3000,
              });
              this.$router.push('/plan');
            } else {
              this.$toast.add({
                severity: 'warn',
                summary: res.message,
                life: 3000,
              });
            }
          },
          (e) => {
            this.$toast.add({severity: 'warn', summary: e.message, life: 3000});
          },
      );
    },
    stzimbss(item) {
      if (item.id == 0) {
        this.stzimb = true;
      } else {
        this.stzimb = false;
      }
    },
    getWeather() {
      if (this.form.province && this.form.city) {
        this.getWeatherIng = true;

        this.$http(
            'plan/getWeather',
            'get',
            {
              province: this.form.province,
              city: this.form.city,
              type: 'year',
            },
            (result) => {
              if (result.data) {
                // console.log(result.data);
                // let s = result.data.substring(1, result.data.length - 1);
                // console.log(s);
                // let list = s.split(",");
                let list = result.data;
                let keys = [
                  'year',
                  't',
                  'tmax',
                  'tmin',
                  'pp',
                  'v',
                  'ra',
                  'sn',
                  'ts',
                  'fg',
                  'tn',
                  'gr',
                ];
                const arr = eval('(' + list + ')');
                keys.forEach((item, index) => {
                  // console.log((list[index]));
                  if (arr[index] === '-') {
                    arr[index] = null;
                  } else {
                    this.form.weatherData[item] = isNaN(Number(arr[index]))
                        ? null
                        : Number(arr[index]);
                  }
                });
                console.log(this.form.weatherData);
              } else {
                this.$toast.add({
                  severity: 'warn',
                  summary: '暂无数据',
                  life: 3000,
                });
              }
              this.getWeatherIng = false;
            },
            () => {
              this.getWeatherIng = false;
            },
        );
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: '请先输入省份城市信息',
          life: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
.p-grid {
  margin: 0 0 30px 0;
}

.weatherBlackUI {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.cont {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}

.weatherTable {
  width: 50%;
  margin-bottom: 1rem;
}

.weatherTable label {
  width: 50%;
  display: inline-block;
  text-align: right;
}

.weatherTable > span {
  width: 25%;
  display: inline-block;
  padding-left: 5%;
}

::v-deep(.p-tabmenu) {
  width: 265px;
  margin: 0 auto;
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
}

.inp {
  width: 110px;
  margin-bottom: 5px;
}

.cols {
  display: flex;
  justify-content: space-between;
}

.colee {
  display: flex;
  justify-content: flex-start;
}

.colee > p {
  margin-right: 20px;
}

.footer {
  display: flex;
  justify-content: flex-start;
}

.uul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.uul > li {
  height: 30px;
  cursor: pointer;
  padding: 0 20px;
}

.typeActive {
  color: #19a423;
  border-bottom: 1px solid #19a423;
}

.p-inputtext {
  width: 100%;
}

::v-deep(.p-fluid .p-inputnumber .p-inputnumber-input) {
  width: 100%;
}

/*::v-deep('.p-button'){*/
/*  white-space: nowrap;*/
/*  width: auto;*/
/*}*/
</style>
